.instructor-card-container {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  width: 200px;
  height: 240px;
  text-align: center;
  border-radius: 0.4rem;
  box-shadow: 0 2px 2px 1.5px rgba(0, 0, 0, 0.2);
  .instructor-card__image {
    margin-top: 3rem;
    width: 100px;
    height: 100px;
    border-radius: 50%;
    img {
      border-radius: 50%;
      width: 100%;
      height: 100%;
      
    }
  }

  .instructor-card__details--p {
    margin-top: 2rem;
   
  }
}
