.card {
    width: 80% !important;
    height: 250px !important;
    margin-left: 50px !important;
  }
  .imageCard {
    width: 100% !important;
    object-fit: cover !important;
    height: 150px !important;
  }
  .topicName {
    margin-top: 30px !important;
    font-size: large !important;
    float: left !important;
    margin-left: 10px !important;
    
    color:"black"!important;
  
  }