.qtable {
  border: 1px solid #000;
  color: #000 !important;
}
.qtable tr td {
  border: 1px solid #000 !important;
  padding: 5px;
  width: 50% !important;
  color: #000;
}
.qtable tr th {
  border: 1px solid #000;
  padding: 5px;
}
.right_size {
  height: 2rem;
  width: 2rem;
  margin-top: 14px;
  margin-left: 10px;
}
.Wrong_size {
  height: 2rem;
  width: 2rem;
  margin-top: 14px;
  margin-left: 10px;
}
