.headingStyle{
    font-size: 30px !important;
    color: gray !important;
    font-family: Lato,'Helvetica Neue',Arial,Helvetica,sans-serif;

    padding: 10px 0;
    margin-top: 80px !important;
    margin-left: 260px;
    margin-bottom: 20px;
    font-weight: bolder;
}