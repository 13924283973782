.profileName{
    font-size: smaller !important ;
}
.role{
    font-size:small;
    padding-left: 5px;

}
.MuiListItemText-primary{
    font-size: smaller !important ;
    margin-top: -40px !important;
    margin-bottom: 10px!important;
    padding-left: 20px;
}
.MuiListItemText-secondary{
    font-size: medium !important;
    margin-left: 20px !important;
    color: black !important;
}