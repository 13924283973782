.main {
  height: 100%;
  width: 100%;
  padding: 25px;
  overflow-y: auto;
  margin: 0px;
}
.browse{
  margin-left: 10%;
}
.heading {
  font-size: 18px;
  color: black;
  font-weight: bold;
  padding-bottom: 20px;
}

.searchoptions {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 90%;
  padding: 20px;
  gap: 50px;
}

.imageholder{
  text-align: center;
  margin: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.labelandselect {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 50px;
  padding: 10px 0px;
}

.label {
  font-size: 14px;
  font-weight: 400;
  width: 150px;
  text-align: end;
}

.select {
  width: 400px;
  height: 30px;
  font-size: 14px;
}

.select:hover {
  cursor: pointer;
}

.buttonholder {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px 0px;
}

.searchbutton {
  font-size: 14px;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  color: white;
  background: var(--header-color);
}

.changebutton {
    font-size: 14px;
    font-weight: bold;
    padding: 5px 10px;
    border: 1px solid var(--color-grey-dark-2);
    border-radius: 5px;
    background: white;
    color: var(--color-grey-dark-2);
}

.searchbutton:hover {
  transform: scale(1.05);
  cursor: pointer;
}

.bottombar {
  padding-top: 20px;
}

.detailsholder{
  padding: 10px;
}

.jobcard {
  height: 100px;
  width: 100px;
  grid-area: jobcard;
}

.recentbox {
  display: grid;
  grid-template-areas: "jobcard jobcard jobcard";
  gap: 40px;
}

.img {
  border-radius: 50%;
  width: 150px;
  height: 150px;
  padding: 10px;
}

.inputLiveSession {
  border: 1px solid rgb(199, 195, 195);
  border-radius: 5px;
  width: 300px;
  height: 30px;
  font-size: 14px;
  padding: 10px;
}

.aboutme{
  border: 1px solid rgb(199, 195, 195);
  border-radius: 5px;
  width: 300px;
  font-size: 14px;
  padding: 10px;
}

.searchheading{
  font-size: 18px;
  color: black;
  font-weight: bold;
}

.searchskills{
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 20px;
  padding: 10px 0px 20px 0px;
}

.topbar{
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px;
}

.select{
  width: fit-content;
  border: 1px solid rgb(199, 195, 195);
  border-radius: 5px;
}

.tag{
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  width: fit-content;
  border: 1px solid rgb(199, 195, 195);
  background: rgb(199, 195, 195);
  border-radius: 5px;
  padding: 5px;
  margin: 5px;
}

.tagholder{
  padding: 10px;
  width: 100%;
  border-radius: 5px;
  border: 1px solid rgb(199, 195, 195);
  height: 150px;
  overflow-y: auto;
  display: flex;
height: auto;
}

.workheading{
  padding-top: 20px;
  font-size: 18px;
  color: black;
  font-weight: bold;
}

.worktopbar{
  padding-top: 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.jobtitle{
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 10px;
}

.title{
  font-size: 14px;
  color: black;
  font-weight: 400;
}

.dateselect{
  height: 30px;
  border-radius: 5px;
  border: 1px solid rgb(199, 195, 195);
  width: fit-content;
}

.jobdescription{
  padding: 20px 0px;
}

.addbutton{
  text-align: center;
  margin: 20px 0px;
  font-size: 14px;
  font-weight: bold;
  padding: 5px 20px;
  border: 1px solid var(--color-grey-dark-2);
  border-radius: 5px;
  background: white;
  color: var(--color-grey-dark-2);
  
}

.description{
  width: 100%;
  padding: 10px;
  font-size: 14px;
}

.academicstable{
  padding-top: 20px;
}

.academics{
  font-size: 18px;
  color: black;
  font-weight: bold;
}

.tableholder{
  padding-top: 20px;
}

.academicstable{
  padding: 20px 10px;
}

.delete:hover {
  cursor: pointer;
}



