.maincontainer{
    width: 100%;
    height: 100%;
    padding: 25px;
    overflow-y: auto;
}

.heading{
    font-size: 16px;
    font-weight: bold;
    padding-bottom: 20px;
}

.jobheading{
    font-size: 16px;
    font-weight: bold;
    padding-bottom: 10px;
}

.left{
    width: 80%
}


.topbar{
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px;
}


.typeholder{
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 30px;
}

.list{
    list-style: disc;
}

.finaltext{
    font-size: 14px;
    font-style: italic;
    font-weight: 200px;
    padding-bottom: 10px;
}

.secondtext{
    font-size: 14px;
    font-weight: 200px;
    padding-bottom: 10px;
}

.status{
    color: green;
    padding-top: 10px;
    font-size: 14px;
    font-weight: 400;
}

.right{
    width: 20%;
    text-align: left;
}

.applicants{
    padding-top: 10px;
    font-size: 14px;
    color: black;
    font-weight: 400;
}

.bottombar{
    padding: 10px;
}

.jobdescription{
    font-size: 14px;
    font-weight: 400;
}

.response{
    font-size: 14px;
    padding: 10px 0px 2px 0px;
    font-weight: 400;
}

.buttonholder{
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 30px;
    padding: 20px 10px;
}

.back{
    font-size: 14px;
    font-weight: bold;
    padding: 5px 10px;
    border: 1px solid var(--color-grey-dark-2);
    border-radius: 5px;
    background: white;
    color: var(--color-grey-dark-2);
}

.back:hover{
    cursor: pointer;
    transform: scale(1.04);
}

.apply{
    font-size: 14px;
    font-weight: bold;
    padding: 5px 10px;
    border: none;
    border-radius: 5px;
    background: var(--header-color);
    color: white;
}

.apply:hover{
    cursor: pointer;
    transform: scale(1.04);
}
.btndisable
{
cursor: not-allowed;
pointer-events: none;

/*Button disabled - CSS color class*/
color: #c0c0c0;
background-color: #ffffff;

}