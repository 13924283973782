.clock-size {
  height: 26px !important;
  width: 26px !important;
  margin-right: 10px;
  margin-top: -5px !important;
}
.cls1,
.cls2 {
  fill: none;
  stroke: #9e9e9e;
  stroke-miterlimit: 10;
  stroke-width: 4.55px;
}
.cls2 {
  stroke-linecap: round;
}
