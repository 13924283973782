.main{
    width: 100%;
    height: 100%;
    padding: 25px;
    overflow-y: auto;
}

.topbar{
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 20px 0px;
}

.heading{
    font-size: 18px;
    color: black;
    font-weight: bold;
    width: 60%;
}

.add{
    font-size: 14px;
    font-weight: bold;
    padding: 5px 10px;
    border: none;
    border-radius: 5px;
    background: var(--header-color);
    color: white;
}

.add:hover{
    cursor: pointer;
    transform: scale(1.04);
}

.sortholder{
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 10px;
    width: 15%;
}

.filter{
    font-size: 14px;
    font-weight: 300;
}

.select{
    width: fit-content;
    border: 1px solid rgb(199, 195, 195);
    border-radius: 5px;
    height: 30px;
    font-size: 14px;
}

.filterbar{
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 20px;
    padding: 10px;
}

.tag{
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    height: 25px;
    width: fit-content;
    border: 1px solid rgb(199, 195, 195);
    border-radius: 5px;
    padding: 5px;
  }

.tagname{
    font-size: 12px;
}

.recentbox{
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-wrap: wrap;
    flex-direction: row;
    gap: 10px;
    padding: 20px;
    width: 100%;
}

.jobcard{
    padding: 20px;
    width: 350px;
    height: fit-content;
}

.topholder{
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    gap: 10px;
    height: 60px;
    width: 100%;
}

.imageholder{
    width: 100px;
    height: 50px;
}

.logo{
    width: 100px;
    height: 50px;
}

.titleholder{
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    gap: 10px;
    flex-direction: column;
    width: 200px;
}

.iconholder{
    width: 50px;
    text-align: right;
}

.icon{
    height: 30px;
    width: 30px;
}

.jobtitle{
    font-size: 16px;
    font-weight: bold;
}

.typeholder{
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 30px;
}

.list{
    list-style: disc;
}

.finaltext{
    font-size: 14px;
    font-style: italic;
    font-weight: 200px;
}

.dateholder{
    padding-top: 10px;
}

.shorttext{
    font-size: 14px;
    color: grey;
    font-weight: 100px;
    padding: 5px 0px;
}

.applicants{
    padding-top: 10px;
    font-size: 14px;
    color: black;
    font-weight: 400;
}

.statusholder{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.status{
    color: green;
    padding-top: 10px;
    font-size: 14px;
    font-weight: 400;
}

.viewjob{
    font-size: 14px;
    font-weight: bold;
    padding: 5px 10px;
    border: 1px solid var(--color-grey-dark-2);
    border-radius: 5px;
    background: white;
    color: var(--color-grey-dark-2);
}

.close:hover{
    cursor: pointer;
}